<template>
  <div class="LandingPage" :class="{slided: isProjectShown}">
    <div class="banner" @mousemove="updateMousePosition">
      <div class="light" :style="lightStyle"></div>
      <button @click="slideBack">
        <img id="leftArrow" src="../assets/images/left-chevron.png" v-if="isProjectShown"/>
      </button>
      <NavMenu></NavMenu>
      <MainContents @project-selected="slideOff" :is-project-shown="isProjectShown"></MainContents>
    </div>
  </div>
</template>

<script>
import NavMenu from "./NavMenu.vue";
import MainContents from "./MainContents.vue";

export default {
  name: "LandingPage",
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      hoverOnOwner: false,
      hoverOnCoach: false,
      showTooltip: false,
      isProjectShown: false,
      projectOffsetTop: 0,
      contentEl: null
    };
  },
  methods: {
    slideOff() {
      this.isProjectShown = true;
      history.pushState({ page: 'newpage' }, 'title', '#newpage');
      this.scrollToTop();
    },
    slideBack() {
      this.isProjectShown = false;
      history.replaceState({page: "newpage"}, "main", "#main");
      this.scrollToProjectOffsetTop();
    },
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    scrollToTop() {
      window.scrollTo({ left: 0, top: 0, behavior: "instant" });
    },
    scrollToProjectOffsetTop() {
      window.scrollTo({ left: 0, top: this.projectOffsetTop+400, behavior: "instant"});
      setTimeout(()=>{
        this.contentEl.scrollTo({ left: 0, top: this.projectOffsetTop, behavior: "instant" });
      }, 500);
    }
  },
  computed: {
    lightStyle() {
      const blurRadius = 150;
      const lightSize =300;
      const offsetX = -100;
      const offsetY = -80;

      return {
        width: `${lightSize}px`,
        height: `${lightSize}px`,
        top: `${this.mouseY}px`,
        left: `${this.mouseX}px`,
        transform: `translate(${offsetX}px, ${offsetY}px)`,
        filter: `blur(${blurRadius}px)`,
      };
    },
  },
  created() {
  },
  mounted() {
    const self = this;
    window.addEventListener('popstate', function(event) {
      if(event.state && event.state.page === 'newpage') {
        self.slideBack();
      }
    })
    this.contentEl = document.getElementById("content");
    this.projectOffsetTop = document.getElementById("projects").offsetTop;
  },
  components: {
    NavMenu,
    MainContents,
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

button {
  border: none;
  background-color: transparent;
}
p { 
  margin: 0;
}
#leftArrow {
  width: 50px;
  position: absolute;
  top: 50vh;
  left: 580px;
  z-index: 999;
}
.LandingPage.slided {
  transform: translateX(-570px);
}
.LandingPage {
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  transition: all 1s;
  width: 100%;
}
.LandingPage > .banner {
  width: 100%;
  /* height: 57vw; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}   
.banner > .light {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(farthest-corner at 40px 40px,#2029AB 0%,#0E1A40 70%, #203D94 100%);
  pointer-events: none;
  z-index: 9;
}
@media only screen and (min-width: 1280px) {

  .LandingPage.slided {
    transform: translateX(-530px);
  }
  .LandingPage.slided .photoSection {
    width: 40%;
    top: 50px;
  }
  .LandingPage.slided .swipeIt {
    top: 60px;
  }
}
@media only screen and (min-width: 1980px) {
  .LandingPage.slided {
    transform: translateX(-717px);
  }
  .LandingPage.slided .photoSection {
    width: 37%;
    top: 87px;
  }
  .LandingPage.slided .swipeIt {
    top: 125px;
  }
  #leftArrow {
    width: 80px;
    left: 820px;
  }
}
@media only screen and (min-width: 2560px) {
  .LandingPage.slided {
    transform: translateX(-880px);
  }
  .LandingPage.slided .photoSection {
      width: 37%;
      top: 87px;
  }
  .LandingPage .swipeIt {
    top: 125px;
  }
  #leftArrow {
    width: 80px;
    left: 1056px;
  }
}

/* @media only screen and (max-width: 924px) {
  
  .LandingPage > .banner {
    height: 85vw;
  }    
}
@media only screen and (max-width: 740px) {
  .LandingPage > .banner {
    height: 103vw;
  }
} */
@media only screen and (max-width: 600px) {
  .LandingPage > .banner {
    background-position: 16%;
    height: 190vw;
    flex-direction: column;
  } 
  .banner > img {
    width: 18em;
  }
  .banner > #leftContainer {
    width: 90%;
    position: relative;
  }
  .LandingPage.slided {
    transform: translateX(-570px);
  }
  .LandingPage.slided .photoSection {
    width: 80%;
    left: 143%;
  }
}
</style>