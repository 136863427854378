<template>
  <div
    :name="staff.uid"
    class="staffPhotoContainer"
    @mouseover="asyncHoverOn"
    @mouseleave="asyncHoverOff"
  >
  <img :src="currentImage" alt="Dynamic Image" />
  </div>
</template>

<script>
export default {
  data(){
    return {
      onHover: false,
      images: [
        'image_600px.png',
        'image2_600px.png',
        'image3_600px.png',
        'image4_600px.png'
      ],
      currentIndex: 0,
    }
  },
  computed: {
    currentImage() {
      return require(`../assets/images/${this.images[this.currentIndex]}`);
      // Adjust the path based on your project structure
    },
  },
  mounted() {
    this.startImageSwitching();
  },
  methods: {
    startImageSwitching() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 3000); // 15 seconds
    },
    asyncHoverOn(){
      setTimeout(()=>this.onHover=true, 0)
    },
    asyncHoverOff(){
      setTimeout(()=>this.onHover=false, 0)
    }
  },
  props: ["staff"]
};
</script>

<style lang="sass">
.staffPhotoContainer 
  margin-bottom: 1rem
  +flexAllCenter
  justify-content: flex-start

  img
    width: 80%
    border-radius: 5px

  .overlay 
    display: none
    border: solid white 1px
    font-size: 1.5em
    background-color: rgba(0, 0, 0, 0.5)
    width: 100%
    height: 26vw
    font-family: "Do Hyeon", sans-serif
    white-space: pre-wrap

  .overlay.show 
    +flexAllCenter
  

@media only screen and (max-width: 740px) 
  .staffPhotoContainer 
  

@media only screen and (max-width: 600px) 
  .staffPhotoContainer 
    justify-content: center
    img
      width: 80%

  .staffPhotoContainer .overlay.show 
    width: 100%
    height: 70vw
  

</style>