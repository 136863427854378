<template>
    <div id="main" class="bg-slate-900">
      <TypeWriter @remove-component="removeTypeWriter"/>
      <router-view v-slot="{ Component }">
        <transition>
          <component :is="Component" v-if="isTypeWriterOff"/>
        </transition>
      </router-view>
    </div>
</template>
<script>
import "bootstrap/dist/css/bootstrap.css";
import TypeWriter from './components/TypeWriter.vue'

export default {
  name: "App",
  compatConfig: {
    MODE: 3
  },
  components: { 
    TypeWriter
  },
  data() {
    return {
      isTypeWriterOff: false
    };
  },
  setup() {
    
  },
  computed: {
  },
  methods: {
    removeTypeWriter() {
      document.getElementById("typeWriter").classList.add("shrinked");
      setTimeout(()=>{
        document.getElementById("typeWriter").classList.add("removed");
        this.isTypeWriterOff = true
      }, 100);
    }
  },
  created(){
  },
  mounted() {
    this.$store.state.isMobile = window.innerWidth <= 924
    window.addEventListener("resize", ()=>{
      this.$store.state.isMobile = window.innerWidth <= 924
    })
  }
};
</script>

<style lang="sass">
#main
  position: relative
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background-image: url('./assets/images/bgbg1.png')
  background-size: cover

a:hover 
  text-decoration-line: none

.loadingIcon
  position: absolute
  left: 45%
  top: 45%

.v-enter-active
  transition: opacity 1.5s ease
.v-leave-active
  transition: opacity 1.5s ease


.v-enter-from
  opacity: 0
.v-leave-to 
  opacity: 0

</style>