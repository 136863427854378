import { createStore } from 'vuex'


const store = createStore({
  state () {
    return {
    isAdmin: false,
    bookingModalShow: false,
    projectModalShow: false,
    staffModalShow: false,
    targetModalId: "",
    openProjectModalHashStateId: "#pmOpen",
    openBookingModalHashStateId: "#bmOpen",
    openStaffModalHashStateId: "#smOpen",
    swiperOn: false,
    isMobile: false,
  }
}})

export default store