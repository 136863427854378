<template>
  <div class="CustomSwiper">
    <!-- swiper -->
    <img src="../assets/images/SwipeIt.png" class="swipeIt"/>
    <swiper-container :slides-per-view="1"
    :space-between="30"
    :centered-slides="true"
    :pagination="{
      hideOnClick: true
    }"
    :breakpoints="{
      768: {
        slidesPerView: 1,
      },
    }"
    >
      <swiper-slide v-for="(url, i) in photoUrls" :key="i">
        <img :src="url" alt="project images" />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>


export default {
  name: "CustomSwiper",
  data() {
    return {
      hideElements: true,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        lazy: true,
        loop: true,
        allowTouchMove: window.innerWidth <= 600, //neither computed nor vuex store data does not work here
        preventInteractionOnTransition: window.innerWidth >= 600,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  methods: {
  },
  props: ["photoUrls", "videoUrls"],
  computed: {
  },
  components: {
    
  },
  mounted() {
  }
};
</script>

<style>
.CustomSwiper {
  width: 100%;
  height: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
}

.swipeIt {
  width: 220px;
  height: auto;
  position: fixed;
  top: 60px;
  left: 110%;
}
@media only screen and (max-width: 600px) {
  .CustomSwiper {
    width: 150%;
  }
  .swipeIt {
    top: 30px;
    left: 188%;
  }
}
</style>