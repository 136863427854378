<template>
  <div id="typeWriter" class="container">
    <h1>
      <span class="typed-text">{{ typeValue }}</span>
      <span v-if="typeStatus" class="blinking-cursor">|</span>
      <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "typeWiriter",
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      displayTextArray: ["Hello! I'm Jay.", "A Full Stack Developer."],
      typingSpeed: 70,
      erasingSpeed: 0,
      newTextDelay: 1000,
      displayTextArrayIndex: 0,
      charIndex: 0,
      repeatCounter: 0
    };
  },
  props: {},
  created() {
    setTimeout(this.typeText, this.newTextDelay);
  },
  methods: {
    typeText() {
      if (this.repeatCounter < 1) {
        if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
          this.typeStatus = true;
          this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(this.charIndex);
          this.charIndex += 1;
          setTimeout(this.typeText, this.typingSpeed);
        } else {
          // 현재 텍스트 타이핑이 끝났으니 딜레이 후 다음 텍스트 타이핑 시작
          setTimeout(() => {
            // 다음 텍스트로 이동
            this.charIndex = 0;
            this.displayTextArrayIndex += 1;

            // 배열의 끝에 도달했는지 확인
            if (this.displayTextArrayIndex >= this.displayTextArray.length) {
              this.displayTextArrayIndex = 0;
              this.repeatCounter += 1;
            }

            // 새로운 텍스트 타이핑 시작
            this.typeValue = ''; // 이전 텍스트를 클리어
            this.typeText();
          }, this.newTextDelay);
        }
      }else {
        this.$emit('removeComponent');
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.displayTextArrayIndex += 1;
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0;
          this.repeatCounter += 1;
        setTimeout(this.typeText, this.typingSpeed + 500);
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
.container 
  width: 100%
  height: auto
  max-height: 100vh
  display: flex
  justify-content: center
  align-items: center
  

.container.shrinked
  max-height: 0
  transition: all 1s ease-out

.container.removed
  display: none

h1 
  font-size: 6rem
  font-weight: normal
  color: #585858
  span.typed-text 
    color: #fff

@media only screen and (max-width: 400px)
  h1
    font-size: 4rem


// Cursor blinking CSS Starts...
.blinking-cursor 
  font-size: 6rem
  color: #2c3e50
  -webkit-animation: 1s blink step-end infinite
  -moz-animation: 1s blink step-end infinite
  -ms-animation: 1s blink step-end infinite
  -o-animation: 1s blink step-end infinite
  animation: 1s blink step-end infinite

@keyframes blink 
  from,
  to 
    color: transparent
  
  50% 
    color: #2c3e50
  

@-moz-keyframes blink 
  from,
  to 
    color: transparent
  
  50% 
    color: #2c3e50
  

@-webkit-keyframes blink 
  from,
  to 
    color: transparent
  
  50% 
    color: #2c3e50
  

@-ms-keyframes blink 
  from,
  to 
    color: transparent
  
  50% 
    color: #2c3e50
  

@-o-keyframes blink 
  from,
  to 
    color: transparent
  
  50% 
    color: #2c3e50
  

// Cursor blinking CSS Ends...
</style>